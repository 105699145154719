import React from 'react';
import {connect, useDispatch} from "react-redux"
import Layout from "../../../components/NonUkResidents/NonHCP/layout";
import SEO from "../../../components/seo"
import styles from "../../../components/innerPage.module.css"
import { graphql,  Link } from 'gatsby';
import { deriveHomePathFromSpace, isNullUndefined, addDisclaimersToCmsLinks, richTextOptions, capitalizeFirstLetter } from "../../../util"
import ScrollAnimation from 'react-animate-on-scroll';
import { showNonUkResidentsNonHcpDisclaimerPopup } from '../../../actions/nonUkResidents/nonHcp/'
import { toggleNonUkResidentsNonHcpDisclaimerPopup } from '../../../controllers/nonUkResidents/nonHcp/disclaimer'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer" 
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser from "react-html-parser"
import { getDefaultNonUkResidentsNonHcpDisclaimerPopupConfigs, config } from '../../../util/configs'
import { Markup } from 'interweave';
import queryString from "query-string"
import { ValidateRoute } from '../../../util/route-helper';
import TemplateWrapper from "../../../components/TemplateWrapper"

export const pageQueryDefaultTemplateNonUkNonHcp = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulDefaultTemplate(slug: { eq: $slug }, spaceId: {eq: $spaceId}) {
            bannerImage {
                file {
                    url
                }
            }
            spaceId
            slug
            title
            bodyText {
                json
            }
            table {
                table
                childMarkdownRemark {
                  html
                }
              }
            parentMenu {
                default_template {
                  parentMenu {
                    title
                    slug
                  }
                }
            }
        }
    }
`

const DefaultTemplatePageNonUkNonHcp = (props) => {
    const dispatch = useDispatch()

    const {bannerImage, slug, title, spaceId, parentMenu, bodyText, table} = props.data.contentfulDefaultTemplate;

    let richText = documentToReactComponents(isNullUndefined(bodyText) ? bodyText : bodyText.json, richTextOptions)

    let bodyHtml = ReactDOMServer.renderToStaticMarkup(richText)

    const showExternalLinkDisclaimer = (externalUrl) => {
        let okCallback = () => window.open (externalUrl, "_blank")
        let closeCallback = () => {}
        let actionConfig = getDefaultNonUkResidentsNonHcpDisclaimerPopupConfigs(okCallback, closeCallback)
        dispatch(showNonUkResidentsNonHcpDisclaimerPopup(actionConfig));
    }
    let [veevaDisclaimer, setVeevaDisclaimer] = React.useState(false);


    React.useEffect(() => {
        let {veevaDisclaimer} = queryString.parse(props.location.search || "");
  
        if(veevaDisclaimer){
          setVeevaDisclaimer(true);
          showExternalLinkDisclaimer(config.defaultBaseUrl)
        }
  
    }, []);
    return ( 
           
        <TemplateWrapper>
            <Layout>
        <SEO title={`${capitalizeFirstLetter(title)} | Non-UK Residents | Non-HCP`} />
        <div className={styles.banner} style={{background:`url(${isNullUndefined(bannerImage) ? "":bannerImage.file.url})`}}>
        <div className={styles.overlay}></div>
                            <div className="container">
                               <div className="row">
                                   <div className="col-12 col-sm-12 col-md-12 ">
                               
                                    <h1 className="">
                                       {title}
                                    </h1> 
                                   <ol className="custom_breadcrumb">
                                   <li className="breadcrumb-item">
                                       <Link to={'/NonUkResidents/Non-HCP/home'}>
                                           Home
                                       </Link>
                                    </li>
                                        {!isNullUndefined(parentMenu) && !isNullUndefined(parentMenu.default_template) && <li className="breadcrumb-item"><a href="#">
                                            {parentMenu.default_template[0].parentMenu.title}    
                                        </a></li>}
                                        <li className="breadcrumb-item active" aria-current="page">
                                            {title}
                                        </li>
                                    </ol>
                                    
                                   </div>
                                   </div>
                            </div>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                <div className={styles.content}>
                        <div className="container pt-5 pb-5">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                                {ReactHtmlParser(bodyHtml, {transform: (node, index) => addDisclaimersToCmsLinks(node, index, showExternalLinkDisclaimer)})}
                            
                                <table className="table table-bordered vertical-align-middle">
                                    <Markup content={table ? table.childMarkdownRemark.html : ""} />
                                </table>
                            
                            </div>                    

                        </div>
                        </div>
               </div>
             </ScrollAnimation>
  
      </Layout>
        </TemplateWrapper>
     );
}
 
export default DefaultTemplatePageNonUkNonHcp;